import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Switch from "@mui/material/Switch";
import Cookies from 'js-cookie';
import SoftDropzone from "components/SoftDropzone";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography"
import ImagePopup from "pages/employee/ImagePopup";

function Document() {
    let params = useParams();
    const { t } = useTranslation();
    const [attachment, setAttachments] = useState('');
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [user, setUser] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [plz, setPlz] = useState('');
    const [ort, setOrt] = useState('');
    const [nr, setNr] = useState('');
    const [gerichtstand, setGerichtstand] = useState('');
    const [steuernummer, setSteuernummer] = useState('');
    const [telephone, setTelephone] = useState('');
    const [mobile, setMobile] = useState('');
    const [website, setWebsite] = useState('');
    const [bank, setBank] = useState('');
    const [bic, setBic] = useState('');
    const [email, setEmail] = useState('');
    const [iban, setIban] = useState('');
    const [openingTimeFrom, setOpeningTimeFrom] = useState('');
    const [openingTimeTo, setOpeningTimeTo] = useState('');
    const [openingDayFrom, setOpeningDayFrom] = useState('');
    const [openingDayTo, setOpeningDayTo] = useState('');
    const [bankdaten, setBankdaten] = useState(0);
    const [gerichtstandStatus, setTerichtstandStatus] = useState(0);
    const [offnungszeiten, setOffnungszeiten] = useState(0);
    const [pdf, setPDFValue] = useState('angebot');
    const [sameAsAngebot, setSameAsAngebot] = useState(false);
    const [pdfThemes, setPdfThemes] = useState([
        {
            name: 'Default',
            pdf_theme: 'default',
        },
        {
            name: 'Premium solaranlagen',
            pdf_theme: 'premium_solaranlagen',
        },
        {
            name: 'Reesvi solaranlagen',
            pdf_theme: 'reesvi',
        },
        {
            name: 'Energieoptimierung Deutschland',
            pdf_theme: 'energieoptimierung_deutschland',
        }
    ]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [files, setFile] = useState();
    const [isModalVisible, setModalVisible] = useState(false);
    const [image, setImage] = useState();

    const [appData, setAppData] = useState({});
    const [refresh, setRefresh] = useState(0);

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        setFile(file)
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(URL.createObjectURL(file));
                openModal();
            }
            reader.readAsDataURL(file);
        }
    }

    const closeModal = () => {
        if (files) {
            const reader = new FileReader();
            reader.onload = () => {
                setAttachments(URL.createObjectURL(files))
                setImage(files)
            }
            reader.readAsDataURL(files);
        }
        setModalVisible(false);
    }

    const openModal = () => {
        setModalVisible(true);
    };

    const croppedPhoto = async (photo) => {
        try {
            const response = await fetch(photo.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], 'photo.jpg', {
                type: 'image/jpeg', // Set the appropriate MIME type
                lastModified: new Date().getTime(), // Set the current timestamp
            });

            setAttachments(URL.createObjectURL(file))
            setImage(file)
            setModalVisible(false);
        } catch (error) {
            console.error('Error fetching and creating file:', error);
        }
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    useEffect(() => {
        if (!appData?.api_url) return;

        callFetch("app/load-settings/" + (sameAsAngebot ? 'angebot' : pdf) + '?appKey=' + appData?.appKey, "GET", [], {}, appData?.api_url).then((res) => {
            setUser(res.data);
            if (res.data?.pdf) {
                setCompanyName(res.data?.company_name);
                setAddress(res.data?.address);
                setPlz(res.data?.plz);
                setOrt(res.data?.ort);
                setNr(res.data?.nr);
                setAttachments(appData?.backend_url + 'storage/attachments/' + res.data?.logo);
                setGerichtstand(res.data?.gerichtstand);
                setSteuernummer(res.data?.tax_number);
                setTelephone(res.data?.telephone);
                setMobile(res.data?.office_phone);
                setWebsite(res.data?.website);
                setBankdaten(res.data?.show_bank_info == 'null' ? 0 : res.data?.show_bank_info);
                setTerichtstandStatus(res.data?.gerichtstand_show == 'null' ? 0 : res.data?.gerichtstand_show);
                setOffnungszeiten(res.data?.opening_hour_show == 'null' ? 0 : res.data?.opening_hour_show);
                setBank(res.data?.bank);
                setBic(res.data?.bank_bic);
                setEmail(res.data?.email);
                setIban(res.data?.bank_iban);
                setOpeningTimeFrom(res.data?.opening_time_from);
                setOpeningTimeTo(res.data?.opening_time_to);
                setOpeningDayFrom(res.data?.opening_day_from);
                setOpeningDayTo(res.data?.opening_day_to);             

                for (let [key, value] of Object.entries(res?.data)) {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                }
                if(sameAsAngebot){
                    setValue('same_as_angebot', 1);
                }
            } else {

                reset()

                setCompanyName('');
                setAddress('');
                setPlz('');
                setAttachments('');
                setOrt('');
                setNr('');
                setGerichtstand('');
                setSteuernummer('');
                setTelephone('');
                setMobile('');
                setWebsite('');
                setBankdaten(0);
                setTerichtstandStatus(0);
                setOffnungszeiten(0);
                setBank('');
                setBic('');
                setEmail('');
                setIban('');
                setOpeningTimeFrom('');
                setOpeningTimeTo('');
                setOpeningDayFrom('');
                setOpeningDayTo('');
            }

        });
    }, [pdf, appData?.api_url, sameAsAngebot]);

    const setPDF = (pdf) => {
        setSameAsAngebot(false)
        setValue('pdf', pdf);
        setImage('');
        setPDFValue(pdf);
    }

    const changeStatus = (field) => {
        if (field == 'bank') {
            if (bankdaten == 1) {
                setBankdaten(0);
                setValue('show_bank_info', 0);
            } else {
                setBankdaten(1);
                setValue('show_bank_info', 1);
            }
        }
        else if (field == 'gerich') {
            if (gerichtstandStatus == 1) {
                setTerichtstandStatus(0);
                setValue('gerichtstand_show', 0);
            } else {
                setTerichtstandStatus(1);
                setValue('gerichtstand_show', 1);
            }
        }
        else if (field == 'offnung') {
            if (offnungszeiten == 1) {
                setOffnungszeiten(0);
                setValue('opening_hour_show', 0);
            } else {
                setOffnungszeiten(1);
                setValue('opening_hour_show', 1);
            }
        }
    }

    const onSubmit = (formData) => {
        if (!appData?.api_url) return;

        formData.pdf = pdf;
        if (image) {
            formData.attachments = !image ? '' : image;
        }

        formData.appKey = appData?.appKey;
        formData.same_as_angebot = (sameAsAngebot || formData?.same_as_angebot == true ? 1 : 0);

        setSaving(true);
        callFetch("app/update-settings", "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);
            setSameAsAngebot(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mt-4 mb-4">
                                <ul class="nav nav-pills mb-3 email-settings" id="pills-tab" role="tablist" style={{ background: 'none' }}>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setPDF('angebot')}>Angebot</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setPDF('auftrag')}>Auftrag</button>
                                    </li>
                                    <li class="nav-item" role="presentation" style={{ maxWidth: '250px' }}>
                                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => setPDF('rechnung')}>Rechnung</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pdf-preview"><p>Preview</p></div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="card mb-4 ">
                            <div className="card-header pb-0">
                                <h6>
                                    {t('Firmendaten')}
                                    {pdf !== 'angebot' ? (
                                        <div className="form-check d-inline-block">
                                            <input
                                                type="checkbox"
                                                className="form-check-input  ms-1"
                                                {...register("same_as_angebot", {
                                                    required: false,
                                                })}
                                                onChange={(e) => {
                                                    setSameAsAngebot(e.target.checked);
                                                }}
                                            />
                                            <label className="mb-0">Same as Angebot</label>
                                            <div className="invalid-feedback">{errors.same_as_angebot && errors.same_as_angebot.message}</div>
                                        </div>
                                    ) : ''}
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3 scroll-cs" style={{ maxHeight: '515px' }}>
                                    <div className="col-md-12">
                                        <input
                                            onKeyUp={(e) => setCompanyName(e.target.value)}
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={t('Companpy Name')}
                                            {...register("company_name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setAddress(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Address')}
                                            {...register("address", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <input
                                            type="email"
                                            onKeyUp={(e) => setNr(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Nr.')}
                                            {...register("nr", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setOrt(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Ort')}
                                            {...register("ort")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setPlz(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('PLZ')}
                                            {...register("plz")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>


                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setTelephone(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Telephone Phone')}
                                            {...register("telephone")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setMobile(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Mobile Phone')}
                                            {...register("office_phone")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={t('Fax')}
                                            {...register("fex")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setEmail(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('E-mail')}
                                            {...register("email")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            onKeyUp={(e) => setWebsite(e.target.value)}
                                            className="form-control form-control-sm"
                                            placeholder={t('Website')}
                                            {...register("website")} />
                                        <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                    </div>


                                    <div className="col-md-12">
                                        <span className="font-size-14-rem font-weight-600">Bankdaten</span> &nbsp;&nbsp; {bankdaten == '1' && <Switch defaultChecked onClick={() => changeStatus('bank')} />} {bankdaten != '1' && <Switch onClick={() => changeStatus('bank')} />}
                                    </div>

                                    {bankdaten != '1' && <>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setBank(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Bankname')}
                                                {...register("bank")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setBic(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('BIC')}
                                                {...register("bank_bic")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setIban(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('IBAN')}
                                                {...register("bank_iban")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}

                                    {bankdaten == '1' && <>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setBank(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Bankname')}
                                                {...register("bank")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setBic(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('BIC')}
                                                {...register("bank_bic")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setIban(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('IBAN')}
                                                {...register("bank_iban")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}


                                    <div className="col-md-12">
                                        <span className="font-size-14-rem font-weight-600">Gerichtstand </span>  &nbsp;&nbsp; {gerichtstandStatus == '1' && <Switch defaultChecked onClick={() => changeStatus('gerich')} />} {gerichtstandStatus != '1' && <Switch onClick={() => changeStatus('gerich')} />}
                                    </div>

                                    {gerichtstandStatus == '1' && <>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setGerichtstand(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Gerichtstand')}
                                                {...register("gerichtstand")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setSteuernummer(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Steuernummer')}
                                                {...register("tax_number")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}

                                    {gerichtstandStatus != '1' && <>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setGerichtstand(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Gerichtstand')}
                                                {...register("gerichtstand")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setSteuernummer(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Steuernummer')}
                                                {...register("tax_number")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}


                                    <div className="col-md-12">
                                        <span className="font-size-14-rem font-weight-600">Öffnungszeiten </span>  &nbsp;&nbsp; {offnungszeiten == '1' && <Switch defaultChecked onClick={() => changeStatus('offnung')} />} {offnungszeiten != '1' && <Switch onClick={() => changeStatus('offnung')} />}
                                    </div>

                                    {offnungszeiten == '1' && <>
                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setOpeningDayFrom(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Mo')} {...register("opening_day_from")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setOpeningTimeFrom(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('00.00')}
                                                {...register("opening_time_from")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setOpeningDayTo(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Fr')} {...register("opening_day_to")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                onKeyUp={(e) => setOpeningTimeTo(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('00.00')}
                                                {...register("opening_time_to")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}

                                    {offnungszeiten != '1' && <>
                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setOpeningDayFrom(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Mo')} {...register("opening_day_from")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setOpeningTimeFrom(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('00.00')}
                                                {...register("opening_time_from")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setOpeningDayTo(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('Fr')} {...register("opening_day_to")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>

                                        <div className="col-md-3">
                                            <input
                                                type="text"
                                                readOnly
                                                onKeyUp={(e) => setOpeningTimeTo(e.target.value)}
                                                className="form-control form-control-sm"
                                                placeholder={t('00.00')}
                                                {...register("opening_time_to")} />
                                            <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                        </div>
                                    </>}

                                    <div className="col-md-12">
                                        <label>{t('Profile Picture')}</label>
                                        <input type="file" accept=".jpg,.png,.jpeg" className="form-control mb-4" onChange={handleImageChange} />
                                        <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('PDF Theme')}</label>
                                        <select
                                            className="form-control"
                                            {...register("pdf_theme", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value="">--</option>
                                            {pdfThemes && pdfThemes.map((pdfTheme) => (
                                                <option value={pdfTheme?.pdf_theme}>{pdfTheme?.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.pdf_theme && errors.pdf_theme.message}</div>
                                    </div>
                                </div>
                                <div className="col-12 mt-4">

                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-sm-6">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div className="row">
                                            <div className="col-sm-7 mb-3">
                                                <img src={attachment} style={{ height: 'auto', maxWidth: '70%' }} />
                                            </div>
                                            <div className="col-sm-5 mb-3">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{companyName}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>PLZ: {plz}, Ort: {ort}</p>
                                                <p style={{ fontSize: '10px' }}><strong>T</strong>: {telephone}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>M</strong>: {mobile}</p>
                                                <p style={{ fontSize: '10px' }}><strong>E</strong>: {email}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>W</strong>: {website}</p>
                                            </div>
                                            <div className="col-sm-12 mt-5">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Ihr persönliches Angebot</p>
                                            </div>
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <p style={{ fontSize: '10px', color: '#A0AEC0' }}>Ort, XX.XX.XXXX</p>
                                            </div>
                                            <div className="col-sm-12" style={{ margin: '77px 0px' }}>
                                                <hr style={{ width: '50%' }} />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{companyName}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>PLZ {plz}, Ort {ort}</p>
                                                {gerichtstandStatus == '1' && <> <p style={{ fontSize: '7px', marginBottom: '0px' }}>Gerichtsstand {gerichtstand}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>Amtsgericht {steuernummer}</p></>}
                                                {gerichtstandStatus != '1' && <> </>}

                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong></strong> </p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>T:</strong> {telephone}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>E:</strong> {email}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>W:</strong> {website}</p>

                                                {offnungszeiten == '1' && <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>Öffnungszeiten:</strong> {openingDayFrom} - {openingDayTo}: {openingTimeFrom} bis {openingTimeTo} Uhr</p>}
                                                {offnungszeiten != '1' && <></>}

                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>

                                                {bankdaten == '1' && <>
                                                    <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{bank}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>BIC: {bic}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>IBAN: {iban}</p>
                                                </>}

                                                {bankdaten != '1' && <>

                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                        <div className="row">
                                            <div className="col-sm-7 mb-3">
                                                <img src={attachment} style={{ height: 'auto', maxWidth: '70%' }} />
                                            </div>
                                            <div className="col-sm-5 mb-3">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{companyName}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>PLZ: {plz}, Ort: {ort}</p>
                                                <p style={{ fontSize: '10px' }}><strong>T</strong>: {telephone}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>M</strong>: {mobile}</p>
                                                <p style={{ fontSize: '10px' }}><strong>E</strong>: {email}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>W</strong>: {website}</p>
                                            </div>
                                            <div className="col-sm-12 mt-5">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Ihr persönliches Auftrag</p>
                                            </div>
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <p style={{ fontSize: '10px', color: '#A0AEC0' }}>Ort, XX.XX.XXXX</p>
                                            </div>
                                            <div className="col-sm-12" style={{ margin: '77px 0px' }}>
                                                <hr style={{ width: '50%' }} />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{companyName}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>PLZ {plz}, Ort {ort}</p>
                                                {gerichtstandStatus == '1' && <> <p style={{ fontSize: '7px', marginBottom: '0px' }}>Gerichtsstand {gerichtstand}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>Amtsgericht {steuernummer}</p></>}
                                                {gerichtstandStatus != '1' && <> </>}
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong></strong> </p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>T:</strong> {telephone}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>E:</strong> {email}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>W:</strong> {website}</p>
                                                {offnungszeiten == '1' && <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>Öffnungszeiten:</strong> {openingDayFrom} - {openingDayTo}: {openingTimeFrom} bis {openingTimeTo} Uhr</p>}
                                                {offnungszeiten != '1' && <></>}
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>

                                                {bankdaten == '1' && <>
                                                    <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{bank}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>BIC: {bic}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>IBAN: {iban}</p>
                                                </>}

                                                {bankdaten != '1' && <>

                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                        <div className="row">
                                            <div className="col-sm-7 mb-3">
                                                <img src={attachment} style={{ height: 'auto', maxWidth: '70%' }} />
                                            </div>
                                            <div className="col-sm-5 mb-3">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{companyName}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}>PLZ: {plz}, Ort: {ort}</p>
                                                <p style={{ fontSize: '10px' }}><strong>T</strong>: {telephone}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>M</strong>: {mobile}</p>
                                                <p style={{ fontSize: '10px' }}><strong>E</strong>: {email}</p>
                                                <p style={{ fontSize: '10px', margin: '0px' }}><strong>W</strong>: {website}</p>
                                            </div>
                                            <div className="col-sm-12 mt-5 ">
                                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Ihr persönliches Rechnung</p>
                                            </div>
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <p style={{ fontSize: '10px', color: '#A0AEC0' }}>Ort, XX.XX.XXXX</p>
                                            </div>
                                            <div className="col-sm-12" style={{ margin: '77px 0px' }}>
                                                <hr style={{ width: '50%' }} />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                                <hr />
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{companyName}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>{address}, {nr}</p>
                                                <p style={{ fontSize: '7px', marginBottom: '0px' }}>PLZ {plz}, Ort {ort}</p>
                                                {gerichtstandStatus == '1' && <> <p style={{ fontSize: '7px', marginBottom: '0px' }}>Gerichtsstand {gerichtstand}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>Amtsgericht {steuernummer}</p></>}
                                                {gerichtstandStatus != '1' && <> </>}
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong></strong> </p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>T:</strong> {telephone}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>E:</strong> {email}</p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>W:</strong> {website}</p>
                                                {offnungszeiten == '1' && <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}><strong>Öffnungszeiten:</strong> {openingDayFrom} - {openingDayTo}: {openingTimeFrom} bis {openingTimeTo} Uhr</p>}
                                                {offnungszeiten != '1' && <></>}
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>
                                                <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}></p>

                                                {bankdaten == '1' && <>
                                                    <p style={{ fontSize: '7px', fontWeight: 'bold', marginBottom: '0px' }}>{bank}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>BIC: {bic}</p>
                                                    <p style={{ fontSize: '7px', marginBottom: '0px' }}>IBAN: {iban}</p>
                                                </>}

                                                {bankdaten != '1' && <>

                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ImagePopup showModal={isModalVisible} openModal={closeModal} photoURL={selectedImage} filePhoto={files} croppedPhoto={croppedPhoto} />
        </>
    );
}

export default Document;
